import * as React from "react";
import _ from "lodash";

import {
  PostSlideCardWrapper,
  PostPreview,
  SliderImage,
  PostDetails,
  PostTitle,
  PostCat,
  Excerpt,
} from "./style";
interface PostSlideCardProps {
  image?: any;
  title: string;
  category?: string
  description?: string
  className?: string;
}

const PostSlideCard: React.FunctionComponent<PostSlideCardProps> = ({
  image,
  title,
  category,
  description,
  className,
  ...props
}) => {
  const addClass = ["featured_card"];

  if (className) {
    addClass.push(className);
  }

  return (
    <PostSlideCardWrapper className={addClass.join(" ")} {...props}>
       <PostPreview className="">
        <SliderImage src={image} alt="post preview" backgroundColor="#EAE8E9" />
      </PostPreview> 
      <PostDetails className="post_details">
        <PostTitle className="post_title">{title}</PostTitle>
        <PostCat className="post_title">{category}</PostCat>
        {description && (
          <Excerpt className="excerpt">{`“${description}”`}</Excerpt>
        )}
      </PostDetails> 
    </PostSlideCardWrapper>
  );
};

export default PostSlideCard;
