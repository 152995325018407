import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import {  Navigation } from "swiper";

import TestimonialSlideCard from '../testimonial-slide-card';
import PostSliderWrapper, { ReviewSwiper, SliderTitle, Box, Contents } from './style';
// import required modules
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Pagination } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "./styles.css";

interface SwiperProps {
  breakpoints?: any;
  navigation: boolean;
  page?:string;
}

const TestimonialSwiper: React.FunctionComponent<SwiperProps> = ({ page, breakpoints = {}, navigation= true }) => {
  
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
      allContentfulReviews {
          nodes {
            title
            category {
              title
              slug
            }
            description {
              description
            }
            image {
              gatsbyImageData(width: 200, height: 200)
            }
          }
        }
    }
  `);
  const posts = data.allContentfulReviews.nodes;
  
  
  const relevantPosts = page ? posts.filter(( node : any) => node.category.slug === page ) :  posts;


  return (
    relevantPosts.length > 0 ?
    <PostSliderWrapper>
      <SliderTitle>Mūsu klientu atsauksmes</SliderTitle>
      <ReviewSwiper
        speed={600}
        slidesPerView={1}
        modules={[FreeMode, Pagination]}
        spaceBetween={30}
        navigation={navigation}
        freeMode={true}
        pagination={{
          clickable: true,
        }}
        breakpoints={relevantPosts.length > 2 ? breakpoints : null}
      >
        {relevantPosts.map((node: any, index: number) => (
          <SwiperSlide key={index}>
           <TestimonialSlideCard
              title={ node.title}
              image={
                node.image == null
                  ? null
                  : node.image.gatsbyImageData
              }
              category={node.category.title}
              description={node.description.description}
            /> 
          </SwiperSlide>
        ))}
      </ReviewSwiper>
    </PostSliderWrapper>: null
  );
};

export default TestimonialSwiper;
