import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import Buttons from '../button/button';
import GatsbyImage from '../gatsby-image';

export const Button = styled(Buttons)`
  border-radius: 3px;
  font-size: 14px;
`;

export const PostSlideCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

`;

export const SliderImage = styled(GatsbyImage)`
border-radius: 9999px;
`

export const PostPreview = styled.div`
 position: relative;
 width: 100px;
 height: 100px;
 @media (max-width: 767px) {
  width: 70px;
  height: 70px;
}
`;


export const PostDetails = styled.div`
  flex: 0 0 100%;
  max-width: 500px;
  background-color: #fff;
  padding: 30px 20px;
  transition: 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 1600px) {
    padding: 20px 20px 30px;
  }
  @media (max-width: 1024px) {
    padding: 10px 10px 20px;
  }
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  
  }
`;

export const ReadMore = styled.div`
  button {
    text-transform: uppercase;
    font-weight: 700;
    &:hover {
      background-color: ${themeGet('primary', '#D7AB92')};
    }
    @media (max-width: 1024px) {
      font-size: 14px;
      height: 42px;
      padding: 0 22px;
    }
  }
`;

export const PostTitle = styled.div`
  font-family: 'Playfair Display',sans-serif;
  font-size: 22px;
  font-weight: 500;
  color: ${themeGet('colors.textColor', '#292929')};
  margin-bottom: 5px;
  line-height: 1.5;
  max-width: 70%;
  text-align: center;
  a {
    color: ${themeGet('colors.textColor', '#292929')};
  }
  @media (max-width: 990px) {
    font-size: 18px;
  }

  @media (max-width: 600px) {
    font-size: 16px;
    max-width: 100%;
    margin-bottom: 10px;
  }
`;

export const PostCat = styled.div`
  font-family: 'Playfair Display',sans-serif;
  font-size: 18px;
  font-weight: 400;
  font-style: italic;
  color: ${themeGet('colors.lightTextColor', '#757575')};
  margin-bottom: 10px;
  line-height: 1.2;
  max-width: 70%;
  text-align: center;
  a {
    color: ${themeGet('colors.lightTextColor', '#757575')};
  }
  @media (max-width: 990px) {
    font-size: 16px;
  }

  @media (max-width: 600px) {
    font-size: 16px;
    max-width: 100%;
    margin-bottom: 10px;
  }
`;

export const Excerpt = styled.p`
  font-family: 'Playfair Display',sans-serif;
  font-size: ${themeGet('fontSizes.5', '17')}px;
  color: ${themeGet('colors.textLightColor', '#5e5e5e')};
  font-weight: 400;
  font-style: italic;
  line-height: 1.4;
  margin-bottom: 30px;
  text-align: center;
  text-overflow: ellipsis;
  @media (max-width: 1600px) {

  }
  @media (max-width: 1024px) {

  }
  @media (max-width: 990px) {
  
  }
  @media (max-width: 575px) {
  
  }
`;
