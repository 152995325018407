import styled from "styled-components";
import { Swiper } from "swiper/react";
import { themeGet } from "@styled-system/theme-get";


export const Box = styled.div`
width: 100%;
background: blue;
display: flex;
flex-direction: column;
`

export const Contents = styled.div`
width: 100%;

align-items: center;
border: 6px dashed red;

`


const PostSliderWrapper = styled.div`
  margin: 0 auto;
  padding: 90px 0 0 0;
  position: relative;
  max-width: 1170px;
  width: 100%;
  && {
    .swiper-pagination {
      bottom: 0;

      .swiper-pagination-bullet {
        background: ${themeGet("colors.primary", "#CFB8A8")};

      }

      .swiper-pagination-bullet-active {
     
        width: 10px;
        height: 10px;
        vertical-align: middle;
      }
    }
  }
`;

export const SliderTitle = styled.h1`

  text-align: center;
  line-height: 1.5;
  font-size: 30px;
  @media (max-width: 990px) {
    font-size: 26px;
  }

  @media (max-width: 600px) {
    font-size: 24px;
    max-width: 100%;
    margin-bottom: 10px;
  }
`;

export const PrevButton = styled.div`
  position: absolute;
  display: block;
  bottom: 50%;
  left: 0;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  color: ${themeGet("colors.primary", "#CFB8A8")};
  cursor: pointer;
  outline: none;
  &.swiper-button-disabled {
    color: #a0a0a0;
    pointer-events: none;
    svg,
    path {
      fill: #a0a0a0;
    }
  }
`;

export const NextButton = styled.div`
  position: absolute;
  display: block;
  bottom: 50%;
  right: 0;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  color: ${themeGet("colors.primary", "#CFB8A8")};
  cursor: pointer;
  outline: none;
  &.swiper-button-disabled {
    color: #a0a0a0;
    pointer-events: none;
    svg,
    path {
      fill: #a0a0a0;
    }
  }
`;


export const ReviewSwiper = styled(Swiper)`
margin-bottom: 10px;

.swiper-button-next , 
.swiper-button-prev {
  color: ${themeGet('primary', '#D7AB92')};
 };

`;

export default PostSliderWrapper;
